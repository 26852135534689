<template>
  <div class="admin-page">
    <h1>Admin panel</h1>
    <div v-if="this.$store.state.SAuth?.access?.isManager">
      <router-link to="/order/admin">Order management</router-link>
    </div>
    <div v-if="this.$store.state.SAuth?.access?.isManager">
      <router-link to="/place/admin">Gym management</router-link>
    </div>
    <div v-if="this.$store.state.SAuth?.access?.isAdmin">
      <router-link to="/company/admin">Company management</router-link>
    </div>
    <div v-if="this.$store.state.SAuth?.access?.isManager">
      <router-link to="/user/admin">User management</router-link>
    </div>
  </div>
</template>

<script>

export default {

  async mounted() {

    if (!await this.checkManager())
      return;

    this.setBreadcrumbs();
  },

  methods: {

    setBreadcrumbs() {

      this.$emit("setBreadcrumbs", [
        'Admin panel'
      ]);
    }
  }

}
</script>

<style scoped>

</style>